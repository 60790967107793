import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/faq/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Add Marketplace offer to my program`}</h2>
    <h3>{`How can I add an offer from the Marketplace to my program?`}</h3>
    <p>{`You must be enabled for Marketplace offers, please connect with your Client Success Manager should you wish to enable Marketplace Offers.`}</p>
    <p>{`Once enabled, please follow below steps on the Fidel Dashboard:
A user should firstly navigate to the Offers menu and click on the Marketplace icon:`}</p>
    <h5>{`Dashboard marketplace`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/marketplace.png",
        "alt": "Marketplace",
        "title": "Dashboard Marketplace"
      }}></img></p>
    <p>{`You can then select from the listing of any offer you wish to add to your program, after clicking you will be displayed with the following screen:`}</p>
    <h5>{`Offer details`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/offer-details.png",
        "alt": "Offer details",
        "title": "Offer details"
      }}></img></p>
    <p>{`By selecting “Add to a program”, the offer will be migrated into the clients program this will include brand and location information.`}</p>
    <b>Note: Merchant Onboarding will always be conducted regardless of whether the location has been picked from the Marketplace or not. Therefore the usual timelines regarding this process still apply.</b>
    <h2>{`Finding MIDs (Merchant IDs)`}</h2>
    <h3>{`How can a merchant / provider find the MIDs (online and in-store)`}</h3>
    <p>{`MIDs are issued to merchants who accept card payments from their payment processor/acquire. They are referred to as Merchant Identification Number, Merchant ID, or specifically to AMEX - an SE Number (Service Entity Number). Merchants may be able to find there MIDs:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`By speaking to your payment processor or acquirer - it may be located on your invoice, bank statement or your merchant portal ;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`There may be a sticker affixed on the side of your POS/terminal that may include the MID;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`It may be printed on a receipt from the POS terminal;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`It may be reference on the merchant’s bank statement for deposits from their card facility.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      